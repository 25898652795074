<template>
  <edit-template>
    <div class="mb-4"></div>
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="150rem" label-position="left">
      <div class="flex">
        <div class="mr-6">
          <el-form-item label="学年名称" prop="school_year_name">
            <el-input v-if="status < 4" v-model="formData.school_year_name" placeholder="请输入" :maxlength="20"></el-input>
            <span v-else>{{ formData.school_year_name }}</span>
          </el-form-item>
        </div>
        <div v-if="status >= 0">
          <el-form-item label="状态">
            <span>{{ status_name }}</span>
          </el-form-item>
        </div>
      </div>

      <div class="box-wrapper">
        <div class="box-item left-box">
          <el-form-item label="上学期名称" prop="last_semester_name">
            <el-input v-if="status < 4" v-model="formData.last_semester_name" placeholder="请输入" :maxlength="20"></el-input>
            <span v-else>{{ formData.last_semester_name }}</span>
          </el-form-item>
          <el-form-item label="上学期开始时间" prop="last_start_date">
            <el-date-picker v-if="status < 1" v-model="formData.last_start_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptions1" @change="handleChange($event, 1)"></el-date-picker>
            <span v-else>{{ formData.last_start_date }}</span>
          </el-form-item>
          <el-form-item label="上学期结束时间" prop="last_end_date">
            <el-date-picker v-if="status < 2" v-model="formData.last_end_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptions2" @change="handleChange($event, 2)"></el-date-picker>
            <span v-else>{{ formData.last_end_date }}</span>
          </el-form-item>
        </div>
        <div class="box-item">
          <el-form-item label="下学期名称" prop="next_semester_name">
            <el-input v-if="status < 4" v-model="formData.next_semester_name" placeholder="请输入" :maxlength="20"></el-input>
            <span v-else>{{ formData.next_semester_name }}</span>
          </el-form-item>
          <el-form-item label="下学期开始时间" prop="next_start_date">
            <el-date-picker v-if="status < 3" v-model="formData.next_start_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptions3" @change="handleChange($event, 3)"></el-date-picker>
            <span v-else>{{ formData.next_start_date }}</span>
          </el-form-item>
          <el-form-item label="下学期结束时间" prop="next_end_date">
            <el-date-picker v-if="status < 4" v-model="formData.next_end_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptions4"></el-date-picker>
            <span v-else>{{ formData.next_end_date }}</span>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <template #footer>
      <template v-if="status < 4">
        <el-button type="primary" @click="handleSubmit('ruleForm')">保存</el-button>
        <el-button @click="handleBack">取消</el-button>
      </template>
      <el-button v-else type="primary" @click="handleBack">返回</el-button>
    </template>
  </edit-template>
</template>
<script>
import {formDataBackMixin, pageLoadingMixin} from "@/utils/mixins";
import {setDatePicker} from "@/utils";
import {getApi2, postApi2} from "@/api";

export default {
	_config: {"route": [{"path": "add", "meta": {"title": "新增"}}, {"path": "detail", "meta": {"title": "查看"}}, {"path": "edit", "meta": {"title": "编辑"}}]},
  mixins: [formDataBackMixin, pageLoadingMixin],
  data() {
    return {
      id: '',
      status: -1, // -1 初始值  0 未开始  1-3 进行中 (1.上学期开始 2上学期结束 3.下学期开始)  4 已完成  5 自定义加载状态值
      status_name: '', // 未开始  进行中  已完成
      formData: {
        school_year_name: '',
        last_semester_name: '',
        last_start_date: '',
        last_end_date: '',
        next_semester_name: '',
        next_start_date: '',
        next_end_date: '',
      },
      rules: {
        school_year_name: [{ required: true, message: '请输入20个字以内的学年名称', trigger: 'blur' }],
        last_semester_name: [{ required: true, message: '请输入20个字以内的上学期名称', trigger: 'blur' }],
        last_start_date: [{ required: true, message: '请选择日期', trigger: 'change' }],
        last_end_date: [{ required: true, message: '请选择日期', trigger: 'change' }],
        next_semester_name: [{ required: true, message: '请输入20个字以内的下学期名称', trigger: 'blur' }],
        next_start_date: [{ required: true, message: '请选择日期', trigger: 'change' }],
        next_end_date: [{ required: true, message: '请选择日期', trigger: 'change' }],
      },
      pickerOptions1: {},
      pickerOptions2: {
        disabledDate: () => true
      },
      pickerOptions3: {
        disabledDate: () => true
      },
      pickerOptions4: {
        disabledDate: () => true
      }
    }
  },
  watch: {
    'formData.last_start_date'(val) { this.handleChange(val, 1)},
    'formData.last_end_date'(val) { this.handleChange(val, 2)},
    'formData.next_start_date'(val) { this.handleChange(val, 3)},
  },
  created() {
    const id = this.$route.query.id
    if(id) {
      this.status = 5
      this.getData(id)
    }
    this.handleChangeStart()
  },
  methods: {
    getData(id) {
      this.httpStart()
      getApi2('/system-base/school-year/details', { id }).then(res => {
        const { id, status, status_name, school_year_name, last_semester_name, last_start_date, last_end_date, next_semester_name, next_start_date, next_end_date } = res
        this.formData.school_year_name = school_year_name
        this.formData.last_semester_name = last_semester_name
        this.formData.last_start_date = last_start_date
        this.formData.last_end_date = last_end_date
        this.formData.next_semester_name = next_semester_name
        this.formData.next_start_date = next_start_date
        this.formData.next_end_date = next_end_date

        this.id = id
        this.status = status ?? -1
        this.status_name = status_name || ''
        this.handleCHangeRules(status)
      }).finally(() => this.httpEnd())
    },
    handleCHangeRules(status) {
      if(!status) return
      if(status === 4) {
        return Object.keys(this.rules).forEach(key => {
          this.rules[key][0].required = false
        })
      }
      if(status === 3) {
        this.rules.next_start_date[0].required = false
      }
      if(status >= 2) {
        this.rules.last_end_date[0].required = false
      }
      this.rules.last_start_date[0].required = false
    },
    handleChangeStart() {
      const start = setDatePicker({ num: 2, type: 'year', yearType: 'start' })
      const end = setDatePicker({ num: -2, type: 'year', yearType: 'end' })
      this.pickerOptions1.disabledDate = (time) => {
        return time.getTime() < start.getTime() || time.getTime() > end.getTime()
      }
    },
    handleChange(value, type) {
      if(!value) {
        if(type === 1) {
          this.pickerOptions2.disabledDate = () => true
          this.formData.last_end_date = ''
        }
        if(type < 3) {
          this.pickerOptions3.disabledDate = () => true
          this.formData.next_start_date = ''
        }
        this.pickerOptions4.disabledDate = () => true
        this.formData.next_end_date = ''
        return
      }

      if(type === 1) {
        return this.pickerOptions2.disabledDate = (time) => time.getTime() < new Date(value).getTime()
      }
      if(type === 2) {
        return this.pickerOptions3.disabledDate = (time) => time.getTime() < new Date(value).getTime()
      }
      if(type === 3) {
        return this.pickerOptions4.disabledDate = (time) => time.getTime() < new Date(value).getTime()
      }
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const api = `/system-base/school-year/${this.id ? 'edit' : 'add' }`
          const data = { ...this.formData }
          if(this.id) {
            data.id = this.id
          }
          this.httpStart()
          postApi2(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.httpEnd())
        }
      })
    },
    handleReset(formName){
      this.$refs[formName].resetFields()
    },
    handleBack() {
      if(this.status === 4) return this.$router.back()
      this.formBack(this.formData)
    }
  }
};
</script>

<style lang="scss" scoped>
.box-wrapper {
  margin-top: 22rem;
  border-top: solid 1rem #e9e9e9;
  border-bottom: solid 1rem #e9e9e9;
  display: inline-flex;
}
.box-item {
  min-width: 545rem;
  padding: 50rem 100rem 30rem 0;
}
.left-box {
  margin-right: 50rem;
  border-right: solid 1rem #e9e9e9;
}
</style>
